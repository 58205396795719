<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="author_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-author-filter></app-author-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="authors"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import AuthorFilter from '../../components/filter/FilterAuthor'
import DataTable from '../../components/shared/DataTable'
import UserService from '../../services/user/UserService'
import ButtonCreate from '../../components/shared/ButtonCreate'
import SiteService from '../../services/site/SiteService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'AuthorListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: 'Id',
          defaultSite: this.$t('author.site'),
          name: this.$t('author.name'),
          originName: this.$t('author.originName'),
          user: this.$t('author.user'),
          slug: this.$t('author.slug'),
          type: this.$t('author.type'),
          hiddenOnPage: this.$t('author.hiddenOnPage'),
          disabled: this.$t('author.disabled'),
          detailPage: this.$t('author.hasDetailPage'),
          createdAt: this.$t('system.created_at'),
          createdBy: this.$t('system.created_by')
        },
        toolTip: {
          originName: '(name for sorting)'
        },
        actions: {
          detail: 'author_detail',
          edit: 'author_edit'
        },
        render: {
          hiddenOnPage: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          disabled: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          detailPage: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          user: (userId) => {
            const user = this.$store.getters['user/userById'](userId)
            if (user) {
              return UserService.getUserInfo(user)
            }

            return userId
          },
          defaultSite: (id) => {
            return SiteService.getSiteTitle(id)
          }
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    authors () {
      return this.$store.getters['author/list']
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    totalCount () {
      return this.$store.getters['author/totalCount']
    },
    page () {
      return this.$store.getters['author/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appAuthorFilter: AuthorFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getAuthors () {
      this.$store.dispatch('author/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('author/setPage', page)
      this.getAuthors()
    }
  },
  mounted () {
    this.getAuthors()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
